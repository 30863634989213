import { message } from 'antd'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import {
  approveFinanceExpense,
  deleteFinanceExpense,
  deleteOnAddFinanceBankTransAttachment,
  expensePaymentFinanceBankTrans,
  getFinanceExpense,
  getFinanceExpenses,
  getFinanceExpensesAccountChart,
  getFinanceExpensesContactChart,
  getFinanceExpensesOverview,
  getRecapFinanceExpenses,
  getRecurringExpenses,
  getTotalExpenses,
  rejectFinanceExpense,
  revertApprovalFinanceExpense,
} from 'utils/apis'
import { optionsSelector } from 'redux/selectors'
import { editOption } from 'redux/options/actions'

export const useExpenses = ({ payload = {}, enabled = false }) => {
  const options = useSelector(optionsSelector)
  const dispatch = useDispatch()

  // prevent user that already setting per_page > 100
  if (payload.per_page > 100) {
    payload.per_page = 100
  }

  if (payload.per_page && payload.per_page !== options.expenses_per_page_default) {
    dispatch(
      editOption({
        expenses_per_page_default: payload.per_page,
      }),
    )
  }

  return useQuery(
    ['expense', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceExpenses(payload)
      return data
    },
    {
      enabled,
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
    },
  )
}

export const useRecapExpenses = ({ payload = {}, options = {} }) => {
  return useQuery(
    ['recap-expenses', payload],
    async () => {
      const {
        data: { data },
      } = await getRecapFinanceExpenses(payload)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}

export const useRecurringExpenses = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['expense', 'recurring', payload],
    async () => {
      const {
        data: { data },
      } = await getRecurringExpenses(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export const useExpenseOverview = ({ payload = {}, enabled = false }) => {
  return useQuery(
    ['expense', 'overview', payload],
    async () => {
      const {
        data: { data },
      } = await getFinanceExpensesOverview(payload)
      return data
    },
    {
      enabled,
      keepPreviousData: true,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useExpense({ id, enabled = false }) {
  return useQuery(
    ['expense', id],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpense(id)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      enabled: !!id && enabled,
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
    },
  )
}

export function useExpenseAccountChart(params = {}, options = {}) {
  return useQuery(
    ['expenseAccountChart', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpensesAccountChart(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export function useExpenseContactChart(params = {}, options = {}) {
  return useQuery(
    ['expenseContactChart', params],
    async () => {
      let response
      try {
        const {
          data: { data },
        } = await getFinanceExpensesContactChart(params)
        response = data
      } catch (error) {
        throw new Error('Failed to load data from server!')
      }
      return response
    },
    {
      onError: (error) => message.error(error?.message || 'Failed to load data from server!'),
      ...options,
    },
  )
}

export const useApproveExpense = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => approveFinanceExpense({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['expense', `${response.data.data.id}`])
      } else {
        message.error(response.data.message)
      }
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRejectExpense = () => {
  const queryClient = useQueryClient()
  return useMutation((payload) => rejectFinanceExpense(payload), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['expense', `${response.data.data.id}`])
      } else {
        message.error(response.data.message)
      }
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useRevertApprovalFinanceExpense = () => {
  const queryClient = useQueryClient()
  return useMutation(({ id }) => revertApprovalFinanceExpense({ id }), {
    onSuccess: (response) => {
      if (response.data.success) {
        message.success(response.data.message)
        queryClient.invalidateQueries(['expense', `${response.data.data.id}`])
      } else {
        message.error(response.data.message)
      }
    },
    onError: (error) => message.error(error.data.message),
  })
}

export const useDeleteExpense = () => {
  return useMutation((payload) => deleteFinanceExpense(payload.id))
}

export const useExpensePaymentFinanceBankTrans = () => {
  return useMutation((data) => expensePaymentFinanceBankTrans(data))
}

export const useDeleteOnAddFinanceBankTransAttachment = () => {
  return useMutation((url) => deleteOnAddFinanceBankTransAttachment(url))
}

export const useTotalExpenses = (params, options) => {
  return useQuery(
    ['total-expenses', params],
    async () => {
      const {
        data: { data },
      } = await getTotalExpenses(params)
      return data
    },
    {
      onError: (error) => {
        message.error(error?.message || 'Failed to load data from server!')
      },
      ...options,
    },
  )
}
